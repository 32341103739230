@import 'customize';

.footer-wrap {
  margin-top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
}
.page-footer {
  color: rgba(255, 255, 255, 0.55);
  background-color: $primary;
}

.page-footer .logo {
  height: 80px;
  fill: white;
  margin-top: 10px;
}

.page-footer .social-links {
  display: flex;
  flex-direction: row;
}

.page-footer .social-links .icon {
  color: white;
  opacity: 65%;
  font-size: 1.6rem;
  padding: 0px 10px 0px 10px;
}

.page-footer .contact-section .email {
  font-size: 1rem;
}

.page-footer a:hover,
.page-footer .social-links .icon:hover {
  color: white;
  opacity: 100%;
}

.page-footer .credits p {
  margin-bottom: 0px;
}

.contact-section h5,
.contact-section p,
.contact-section a {
  margin-bottom: 0px !important;
}
.contact-section .row .col {
  margin-bottom: 5px !important;
}

@include media-breakpoint-down(sm) {
  .page-footer .contact-section {
    margin-top: 10px !important;
  }

  .page-footer .contact-section .email {
    margin-top: 10px !important;
    margin-bottom: 3px !important;
  }
  .credits {
    width: 300px;
  }
}
