@import 'customize';

.rsvp-form-card {
  width: 100%;
}

// @include media-breakpoint-down(md) {
//   .rsvp-form-card {
//     min-width: 200px !important;
//     width: 100%;
//   }
// }
