@import 'customize';

html,
body {
  padding-right: 0 !important;
  overflow-x: hidden;
}

$bodyPaddingTop: 0px;

ul {
  list-style: none;
  margin-bottom: 0 !important;
}

body {
  margin: 0;
  font-family: 'Georgia Pro Cond', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  position: relative;
  padding-top: -10px;
  font-variant-numeric: lining-nums !important;
  font-feature-settings: 'lnum' 1 !important;
  font-variant-numeric: tabular-nums !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.section-title h2 {
  font-family: 'Brittany';
  font-size: 2.4rem;
  font-weight: bold;
}
.page-section {
  margin-top: 0px;
  scroll-margin-top: 57px;
}

.vline1 {
  border-left: 2px solid black;
  height: 40px;
  position: absolute;
  left: 10%;
}

.vline2 {
  border-left: 2px solid black;
  height: 40px;
  position: absolute;
  left: 15%;
}

.gold-link {
  color: #a0743e;
}

.fab-menu {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  bottom: 54px;
  right: 200px;
}

.fab-menu .fab-menu-button {
  z-index: 10;
  font-weight: bold;
  display: flex;
  height: 56px;
  width: 56px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50% !important;
}

.fab-menu .item {
  display: none;
}

.fab-menu.active .item {
  font-weight: bold;
  display: flex;
  height: 45px;
  width: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50% !important;
  display: inline-block;
}

.brittany {
  font-family: 'Brittany';
}

.font-900 {
  font-weight: 900;
}

.vh-75 {
  height: 75vh;
}

.test-border {
  border: 1px solid red;
}

.hide {
  display: none;
}

.scroll-please {
  overflow: auto !important;
}

.scroll-off {
  overflow: hidden !important;
}

.btn-no-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.page-layout {
  min-height: 100dvh;
  overflow-x: hidden;
}

@include media-breakpoint-down(xxl) {
  .fab-menu {
    bottom: 54px;
    right: 54px;
  }
}

@include media-breakpoint-down(md) {
  .section-title h2 {
    font-size: 2rem;
  }

  .fab-menu {
    bottom: 24px;
    right: 24px;
  }
  .fab-menu .fab-menu-button {
    height: 56px;
    width: 56px;
  }
}

@include media-breakpoint-down(sm) {
  .section-title h2 {
    font-size: 1.4rem;
  }

  .fab-menu {
    bottom: 16px;
    right: 16px;
  }
  .fab-menu .fab-menu-button {
    height: 52px;
    width: 52px;
  }

  .fab-menu .item {
    height: 40px;
    width: 40px;
  }
}
