@import 'customize';

.full-screen-splash {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  background-color: black;
  z-index: 1100 !important;
  animation: fade 2s linear 2s 1 forwards;
}

.fade-out {
  animation: fade 3s linear 2s 1 forwards;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.logo-hero {
  z-index: 2;
  position: absolute !important;
  top: calc(var(--vh, 1vh) * 100 - 225px) !important;
  left: 50%;
  stroke: white;
  stroke-width: 3px;
  transform: translate(-50%, -50%);
  height: 200px;
}

.logo-load {
  z-index: 1101 !important;
  visibility: hidden;
  stroke-dasharray: 1000;
  animation: logo-fill 4s linear 1s 1 forwards;
}

@keyframes logo-fill {
  from {
    visibility: visible;
    stroke-dashoffset: 1000;
  }

  to {
    visibility: visible;
    z-index: 2 !important; 
    stroke-dashoffset: 0;
  }
}


.hide {
  display: none;
}

@include media-breakpoint-down(xxl) {
  .logo-hero {
    height: 150px;
    top: calc(var(--vh, 1vh) * 100 - 200px) !important;
  }
}

@include media-breakpoint-down(md) {
  .logo-hero {
    top: 120px !important;
    height: 100px;
  }
}
