@import 'customize';

.clock-area {
  position: relative;
  top: -2px;
}
.countdownwrap {
  padding: 40px;
  margin: auto;
  position: relative;
  overflow: hidden;
  top: -20px;
}

.time-block {
  display: inline-block;

  text-align: center;
  position: relative;
  height: 5em;
  background-size: cover;
  color: rgb(238, 237, 237);
  min-width: 65px;
}

.time-block:first-child p.value,
.time-block:first-child p.label {
  margin-right: 5px;
}

.time-block p.value {
  font-size: 3rem;
  letter-spacing: 0.01rem;
  word-spacing: 0.1rem;
  margin-bottom: -12px;
}
.time-block p.label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.clock-area span.divider {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  padding-left: 5;
  padding-right: 5;
  margin-top: -10px;
}

.section-area {
  position: relative;
  text-align: center;
  align-items: center;
}

.section-area h2 {
  font-size: 45px;

  text-align: center;
  color: #fff;
}
.clock-area {
  background: black;
  height: 6rem;
  align-items: center;
}

@include media-breakpoint-down(md) {
  .clock-area {
    height: 4rem !important;
  }

  .time-block {
    margin: 0 5px !important;
    height: 4em !important;
    min-width: 45px;
  }
  .time-block p.value {
    font-size: 2rem !important;
    margin-bottom: -10px !important;
  }

  .time-block p.label {
    font-size: 0.85rem !important;
  }
  .clock-area span.divider {
    font-size: 1rem !important;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;

    margin-top: -15px !important;
  }
}
