@import 'customize';

.logo-wrap {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: 120px;
  z-index: 500;
}

.hero-wrap {
  position: relative;
  top: -3px;
}

.carousel_mobile {
  display: none;
}

.carousel_desktop {
  display: block;
}

.car-image {
  height: calc(100vh - 145px);
  height: calc(var(--vh, 1vh) * 100 - 145px) !important;
  width: 100vw;
  display: block;
  object-fit: cover;
}

.cover {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.15) 60%,
    rgba(0, 0, 0, 4) 100%
  );
}

.cover .black {
  background-color: black;
}

.carousel-inner {
  overflow: visible;
}

@include media-breakpoint-down(md) {
  .cover {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0.15) 60%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
  .car-image {
    height: calc(var(--vh, 1vh) * 100 - 112px) !important;
  }
  .logo-wrap {
    top: 0px;
  }
  .logo {
    height: 100px;
    margin-top: 40px;
  }

  .carousel_mobile {
    display: block;
  }
  .carousel_desktop {
    display: none;
  }
}
