@import 'customize';

.access-challenge-card {
  min-width: 300px !important;
}

.guest-registration-wrapper {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.code-confirmation .form-control:focus {
  border-color: $primary;
  box-shadow: none;
}

.code-confirmation input {
  width: 3em;
  height: 3.8em;
  padding: 1.15em;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.guest-response__icon {
  font-size: 2em;
}

.guest-card:hover {
  cursor: pointer;
  border: 1px solid $primary;
}

.guest-card .card-body {
  min-height: rem;
}

.guest-card__header {
  font-family: 'Brittany', serif;
  font-size: 1.4rem;
}

.guest-card__meal-selection {
  text-transform: capitalize;
}

.guest-card__response-icon {
  font-size: 1.2rem !important;
}

.guest-card.accepted .guest-card__response-icon {
  color: green;
}

.guest-card.declined .guest-card__response-icon {
  color: red;
}

.guest-card.awaitingresponse .guest-card__response-icon {
  color: orange;
}

.guest-card__name {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 2px !important;
}

.guest-card__content-row {
  min-height: 8rem;
}

.return-card:hover {
  border: 1px solid $primary;
  cursor: pointer;
}

.rsvp-icon {
  font-size: 22px;
  -webkit-text-stroke: 0.75px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.guest-card .card-header {
  background-color: white;
  color: rgb(160, 116, 62, 1);
}

.sample-rsvp-img {
  height: 300px;
}

@include media-breakpoint-up(sm) {
  .guest-card__header {
    font-family: 'Brittany', serif;
    font-size: 2rem;
  }
  .guest-card__name {
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  .guest-card__meal-selection {
    font-size: 1.2rem;
  }

  .sample-rsvp-img {
    height: 400px;
  }
}

@include media-breakpoint-up(lg) {
  .sample-rsvp-img {
    height: 500px;
  }
}

.no-border {
  border: none !important;
}
