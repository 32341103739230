@import 'customize';

#bride-groom-wrap {
  margin-bottom: 200px !important;
}
.person-area {
  background: #f9f9f9;
}

.person-item {
  padding-top: 50px;
}

.person-wrap {
  margin-bottom: 30px;
  overflow: hidden;
  z-index: -1;
}

.person-item2 {
  padding-top: 50px;
}

.person-img {
  position: relative;
  overflow: hidden;
  border-radius: 3%;
}

.person-img img {
  width: 100%;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.person-wrap:hover .person-img img {
  -webkit-transform: scale(1.2) rotate(5deg);
  -ms-transform: scale(1.2) rotate(5deg);
  transform: scale(1.2) rotate(5deg);
}

.social-list {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 44;
  opacity: 0;
  text-align: center;
  transition: all 0.5s;
}

.person-wrap {
  overflow: hidden;
}

.person-content {
  text-align: center;
  background: #fff;
  padding: 10px;
}

.person-content h3 {
  font-size: 1.8rem;
  color: #a0743e;
  font-family: 'Brittany';
  padding-top: 7px;
  padding-bottom: 2px;
  line-height: 2.6rem;
}

.person-content h4 {
  font-family: 'Brittany';
  font-size: 1.2rem;
}

.person-content .title {
  font-size: 12px;
  text-transform: uppercase;
}

.person-btn ul {
  justify-content: center;
  margin-bottom: 30px !important;
  border-bottom: 1px solid #000000;
}

.person-btn ul li a.nav-link {
  border: none;
  display: inline-block;
  padding: 10px 30px;
  background: #a0743e;
  transition: all 0.3s;
  font-size: 25px;
  border-right: 1px solid #fff;
  color: #fff;
  border-radius: 0;
  cursor: pointer;
}
.person-btn ul li:last-child a {
  border-right: none;
}
.person-btn .nav-tabs .nav-item.show .nav-link,
.person-btn .nav-tabs .nav-link.active {
  background: #a0743e;
  color: #fff;
}
.person-btn .nav-tabs .nav-item .nav-link:hover {
  background: #a0743e;
  color: #fff;
}

@include media-breakpoint-up(xl) {
  .person-wrap {
    margin-bottom: 30px;
  }
}

@include media-breakpoint-down(xl) {
  .person-content h3 {
    font-size: 1.4rem;
    word-spacing: 255px;
    line-height: 2.4rem;
  }
}
