@import 'customize';

.media-carousel-controls {
  width: 90svw;
  display: flex;
  flex-direction: row;
}

.media-carousel-controls .slideshow-btn {
  margin-right: auto;
}

.media-carousel-controls .slideshow-btn:hover {
  background-color: white;
  color: black;
}

.media-carousel-controls .slideshow-btn-icon {
  color: lightgrey;
  margin-left: 5px;
}

.media-carousel-controls .slideshow-btn:hover .slideshow-btn-icon {
  color: black;
}

.media-carousel-controls .exit-btn {
  background: transparent !important;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;

  height: 30px;
  margin-left: auto;
}

.media-carousel {
  // position: absolute !important;
  align-self: center;
  justify-self: center;
  left: 0;
  right: 0;
}

.media-carousel .carousel-control-next {
  bottom: 100px;
}

.media-carousel .carousel-control-prev {
  bottom: 100px;
}

.media-carousel .media-wrap {
  background-color: black;
  width: 90svw;
  height: 90svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.media-carousel .media-wrap video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.btn-controlled-file-input {
  display: none;
}

.gallery-preview-card {
  border: 2px solid green !important;
}

.gallery-preview-name {
  font-weight: bold;
}

.image-col {
  width: 230px !important;
}

.thumbnail-placeholder {
  width: 200px;
  height: 150px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: auto;
}
a.gallery-preview-card:link {
  text-decoration: none !important;
}

a.gallery-preview-card:visited {
  text-decoration: none;
}

a.gallery-preview-card:hover {
  text-decoration: none;
}

a.gallery-preview-card:active {
  text-decoration: none;
}

.preview-thumbnail-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-thumbnail-wrapper img {
  object-fit: cover;
  width: 180px;
  height: 120px;
  z-index: 10;
  transition: all 0.5s;
  box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.5);
  opacity: 1;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.9;
}

.preview-thumbnail-wrapper img:nth-child(odd) {
  width: 150px;
  height: 100px;
}

.gallery-preview-card:hover .preview-thumbnail-wrapper img:nth-child(odd) {
  transform: scale(0.9);
}

.gallery-preview-card:hover .preview-thumbnail-wrapper img:nth-child(2) {
  transform: scale(1.1);
}

.gallery-preview-card:hover img {
  opacity: 1;
}

.image-preview {
  position: relative;
  width: 120px !important;
  height: 100px !important;
}
.image-preview img {
  object-fit: cover;
  //margin-left: -10px;
  width: 120px;
  height: 100px;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.image-preview video {
  //margin-left: -10px;
  width: 120px;
  height: 100px;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.image-preview .video-icon {
  //margin-left: -10px;
  position: absolute;
  padding: 0px;
  margin: auto;
  top: 1px;
  right: 1px;
  font-size: 16px;
  color: lightgrey;
}

.image-preview .exit-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  margin-left: 3px;
  padding: 0px;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 5px;
  background: rgba(44, 44, 44, 0.8);
  padding: 0px;
  margin: 0px;
  color: white;
}

.gallery-thumbnail {
  position: relative;
}

.gallery-thumbnail img {
  object-fit: cover;
  width: 230px;
  height: 230px;
  border: 1px solid black;
}
.gallery-thumbnail:hover {
  cursor: pointer;
}

.gallery-section.selectable .gallery-thumbnail:hover {
  cursor: default;
}

.gallery-thumbnail .thumbnail-icon {
  position: absolute;
  display: none;
  padding: 0px;
  margin: 0px;
}
.gallery-thumbnail .thumbnail-icon.check {
  top: 0px;
  left: 5px;
}

.gallery-thumbnail .thumbnail-media-icon {
  position: absolute;
  padding: 0px;
  margin: auto;
  top: 5px;
  right: 5px;
  font-size: 23px;
  color: white;
}

.gallery-thumbnail .thumbnail-icon.expand {
  justify-content: center;
  text-align: center;
  background: rgb(248, 239, 239, 0.3);
  align-items: center;
  bottom: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  border: 1px solid black;
  color: black;
}

.gallery-thumbnail .thumbnail-icon.expand svg {
  display: inline-block;
  vertical-align: middle;
}

.gallery-section.selectable .gallery-thumbnail .thumbnail-icon {
  display: block;
}

.uploader-btn {
  max-width: 200px;
}

.carousel-exit-btn {
  background: transparent !important;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: fixed;
  z-index: 30000;
  top: 10px;
  right: 25px;
}

.preview-modal figure {
  display: flex !important;
  flex-direction: column !important;
}

.preview-modal figure img {
  height: 90% !important;
  margin-top: auto;
}

.carousel-inner img {
  margin: auto;
}

@include media-breakpoint-down(lg) {
  .image-col {
    width: 200px !important;
  }

  .gallery-thumbnail img {
    width: 200px;
    height: 200px;
  }
}

@include media-breakpoint-down(md) {
  .image-col {
    width: 180px !important;
  }
  .gallery-thumbnail img {
    width: 180px;
    height: 180px;
  }

  .preview-thumbnail-wrapper img {
    width: 180px;
    height: 180px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .preview-thumbnail-wrapper img:nth-child(odd) {
    width: 100px;
    height: 66px;
    opacity: 0.9;
  }
}

@include media-breakpoint-down(sm) {
  .image-col {
    width: 120px !important;
  }
  .gallery-thumbnail img {
    width: 120px;
    height: 100px;
  }

  .preview-thumbnail-wrapper img {
    width: 120px;
    height: 80px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .preview-thumbnail-wrapper img:nth-child(odd) {
    width: 100px;
    height: 66px;
    opacity: 0.9;
  }
}
