@import 'customize';

.Ceremony-wrap {
  margin-top: -10px;
}
#events-section {
  scroll-margin-top: 45px !important;
}
.service-area2 .Ceremony-wrap {
  background: #f5fbfb;
}
.ceromony-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ceromony-content h3 {
  font-size: 30px;
  color: #000000;
  margin-bottom: 5px;
}

.ceromony-content h5 {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.ceromony-content a {
  color: #a0743e;
  text-decoration: none;
}

.ceromony-content .info-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.ceromony-content p {
  margin-bottom: 0.5rem;
  padding-right: 20px;
}

.ceromony-img img {
  width: 100%;
  height: 100%;
  border-radius: 2%;
}

.event-date {
  padding-bottom: 2rem;
}

.event-date .dow {
  text-align: center;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.event-date .mdy {
  display: flex;
  padding-top: 5px;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: space-between;
}
.event-date .mdy .item:first-child {
  padding-left: 10px;
  margin-bottom: -3px;
}

.event-date .mdy .item:last-child {
  padding-right: 10px;
  margin-bottom: -3px;
}

.event-date .mdy .item {
  font-size: 1.3rem;
  display: flex;
  letter-spacing: 0.35rem;
  justify-content: flex-end;
}

.event-date .mdy .item span {
  align-self: flex-end;
  margin-bottom: 0px;
}

.event-date .mdy .item.day {
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-left: 5px;
  margin-right: 7px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 2rem;
  height: 2rem;
}

.event-date .mdy .item.day span {
  height: 2rem;
  margin-bottom: 0.5rem;
}

.event-date .title {
  padding-top: 5px;
  font-family: 'Brittany';
  text-align: center;
  font-size: 2.2rem;
}
.event-info-wrap {
  padding-top: 0px;
}

.event-title {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}
@include media-breakpoint-down(lg) {
  .event-date .dow {
    text-align: center;
    font-size: 1.4rem;
  }

  .event-date .mdy .item {
    font-size: 1rem;
    display: flex;
    letter-spacing: 0.2rem;
    justify-content: flex-end;
  }
  .event-date .mdy .item.day {
    font-size: 1.4rem;
    height: 1.4rem;
  }
  .event-date .mdy .item.day span {
    height: 1.4rem;
    margin-bottom: 0.3rem;
  }

  .event-date .title {
    padding-top: 5px;
    font-family: 'Brittany';
    text-align: center;
    font-size: 2rem;
  }
}

@include media-breakpoint-down(md) {
  .event-date {
    text-align: center;
  }

  .event-title {
    font-size: 0.9rem;
  }
}
