@import 'customize';


.user-icon {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;

  border: 1px solid;
  color: #000;
  text-align: center;
 
}



.fixed-nav {
  position: fixed;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar.transparent {
  opacity: 0.9;
}

.nav-link {
  text-transform: uppercase;
}

@include media-breakpoint-up(md) {
  .navbar {
    flex-direction: column;
  }

  .navbar-collapse {
    width: 100% !important;
  }

  .nav-link {
    font-size: 1.2rem;
  }
}

.menu-wrapper {
  height: 56px;
  background: black;
}

.dropdown-menu-dark {
  background-color: black !important;
  text-transform: uppercase !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 100% !important;
  border: 1px solid white;
}

@include media-breakpoint-down(md) {
  .dropdown-menu-dark {
    transform: none !important;
  }
  .navbar {
    justify-content: end !important;
  }

  .navbar.brand-bar {
    display: none;
  }

  .navbar-toggler {
    float: right !important;
    border: none !important;
    font-size: 1rem !important;
  }

  .dropdown-menu-dark .dropdown-item {
    font-size: 0.9rem;
  }

  .dropdown-menu-dark .dropdown-item {
    font-size: 0.9rem;
  }

  // .dropdown-menu-dark.show .dropdown-item:last-child:after {
  //   content: '';
  //   height: 1px;
  //   /* I've removed the vendor prefixes, if you are looking to support older browsers
  //      then refer to older version of this answer.
  //   */
  //   width: 5%;
  //   margin: 0px auto;
  //   border-bottom: 3px solid #fff;
  //   display: block;
  // }

  // .dropdown-menu-dark.show .dropdown-item:first-child:before,
  .dropdown-menu-dark.show .dropdown-item:last-child:after {
    content: '';
    height: 1px;
    /* I've removed the vendor prefixes, if you are looking to support older browsers
       then refer to older version of this answer.
    */
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 40%,
      #fff 50%,
      rgba(0, 0, 0, 0) 60%
    );
    display: block;
  }
  .dropdown-menu-dark.show .dropdown-item:first-child:before {
    margin-bottom: 10px;
  }
  .dropdown-menu-dark.show .dropdown-item:last-child:after {
    margin-top: 15px;
  }

  .navbar.menu-bar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .dropdown-menu-dark {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }


}
