@import 'customize';

.hotel-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
}
.hotel-card .instructions {
  font-style: italic;
}

.hotel-content h3 {
  font-size: 30px;
  color: #000000;
  margin-bottom: 0px !important;
}

.hotel-content a {
  color: #649e93;
  font-weight: 600;
}

.hotel-content span {
  display: block;
}

.hotel-content p {
  padding: 20px 0;
  padding-right: 20px;
}

@include media-breakpoint-down(lg) {
  .hotel-content {
    padding: 20px;
  }
}
