/*===============================
Story-Area
====================================*/

@import 'customize';

.story {
  position: relative;
  padding: 2em 0;
  margin-bottom: 3em;
}

.story button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
}

.story .content-wrapper::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  height: 97%;
  width: 3px;
  background: $primary;
}

.story .item {
  position: relative;
  clear: both;
  width: 50%;
  margin-top: 0px;
  scroll-margin-top: 30px;
}

.story .item:not(:first-child) {
  margin-top: -250px;
}

.story .item .story-content {
  position: relative;
  max-width: 60%;
  padding: 20px;
  background: #fff;
  // border: 1px solid $primary;
  border-radius: 10px;
  top: 10%;
  text-align: right;
}

.story .item.expanded .story-content {
  max-width: 70%;
}
.story .item:nth-child(odd) .story-content {
  float: right;
  margin-right: 50px;
}
.story .item:nth-child(even) .story-content {
  float: left;
  margin-left: 55px;
}

.story .item .story-content > p,
.story .item .story-content img {
  margin: 0;
  width: 100%;
}
.story .item .story-content > p {
  margin-bottom: 20px;
}
.story a.story-btn {
  font-weight: 500;
  color: #5e9a8d;
  text-transform: uppercase;
  font-size: 17px;
}
.story .item:nth-child(odd) {
  float: left;
}

.item:nth-child(odd) .story-content::before,
.item:nth-child(odd) .story-content::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 100%;
  margin-top: -4px;
  border: 13px dashed transparent;
  border-color: transparent;
  border-left-color: $primary;
  display: none;
}

.item:nth-child(odd) .story-content::after {
  margin-top: -3px;
  margin-left: 0px;
  border: 12px solid transparent;
  border-left-color: #fff;
}

.story .item:nth-child(even) {
  float: right;
}

.item:nth-child(even) .story-content::before,
.item:nth-child(even) .story-content::after {
  content: '';
  position: absolute;
  top: 10%;
  right: 100%;
  left: -30px;
  margin-top: -4px;
  border: 15px solid transparent;
  border-color: transparent;
  border-right-color: $primary;
  display: none;
}

.item:nth-child(even) .story-content::after {
  margin-top: -3px;
  margin-right: 0px;
  border: 14px solid transparent;
  border-right-color: #fff;
}

.story .item .story-content .image-wrap {
  margin-bottom: 30px;
  min-width: 250px;
}

.story .story-content h2 {
  margin-bottom: 2px;

  font-size: 1.6rem;
  color: $primary;
}

.story .story-content .btn-link {
  font-size: 12px;
}

.story .story-content .date {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 0px;
  text-align: left;
  color: #616263;
  font-size: 18px;
}

.story-icon-img {
  height: 65px;
  width: 65px;
  fill: #050505;
}

.story .item:nth-child(even) .story-content {
  text-align: left;
}
.story .item:nth-child(even) .story-content .date {
  text-align: left;
  //left: -140%;
}
.story .item:nth-child(odd) .story-content .date {
  text-align: right;
}
.btn2 a {
  background: #9a8c40;
  color: #fff;
}
.story .story-icon {
  position: absolute;
  top: 10%;
  right: -30px;
  width: 80px;
  height: 80px;
  margin-left: -28px;
  text-align: center;
  font-size: 18px;
  line-height: 60px;
  border-radius: 10%;
  background: #ffffff;
  //content: '\f108';
  font-family: 'Flaticon';
  border: 1px solid $primary;
  align-items: center;
  justify-content: center;
  display: flex;
}

.story .item .story-icon:hover,
.story .item .story-icon:focus-visible {
  border-color: $secondary;
}

.story .item .story-icon:hover .story-icon-img,
.story .item .story-icon:focus .story-icon-img {
  fill: $secondary;
}

.story .item.expanded .story-icon {
  border-color: $secondary !important;
}

.story .item.expanded .story-icon-img {
  fill: $secondary !important;
}

.story .item:nth-child(even) .story-icon {
  right: auto;
  left: 0;
}

.story .item:hover .story-icon span {
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.story .story-icon span:before {
  margin-left: 0px;
}

.story-area a.theme-btn {
  color: #333;
}
.story-area a.theme-btn:hover {
  color: #5e9a8e;
}

.story .item .expand-button:after {
  content: 'Show less';
}
.story .item.collapsed .expand-button:after {
  content: 'Read more';
}

.story .item.collapsed .story-content .story-text,
.story .item.collapsed .story-content .image-wrap {
  display: none;
}

.story .item:not(:first-child) {
  margin-top: -100px;
}

.story .item.collapsed + .item {
  margin-top: 10px;
}
@include media-breakpoint-down(md) {
  .story-icon,
  .story-top,
  .story-bottom,
  .story .content-wrapper::before {
    left: 35px;
  }

  .story .item {
    width: 100%;
  }

  .story .item .story-content,
  .story .item:nth-child(even) .story-content,
  .story .item.expanded .story-content {
    width: calc(76vw - 140px);
    margin-left: 20%;
    max-width: 80%;
    float: none !important;
  }

  .story .item:nth-child(odd) {
    float: none;
  }

  .story .item:nth-child(even) {
    float: none;
  }

  .story .item:not(:first-child) {
    margin-top: 30px;
  }

  .story .item:nth-child(even) .story-icon {
    right: auto;
    left: 35px;
  }

  .item:nth-child(odd) .story-content::before,
  .item:nth-child(odd) .story-content::after {
    content: '';
    position: absolute;
    right: 100%;
    left: -30px;
    margin-top: -4px;
    border: 15px solid transparent;
    border-color: transparent;
    border-right-color: $primary;
    display: none;
  }

  .item:nth-child(odd) .story-content::after {
    margin-top: -3px;
    margin-right: 0px;
    border: 14px solid transparent;
    border-right-color: #fff;
    display: none;
  }

  .item:nth-child(odd) .story-content:hover:after {
    border-right-color: #ffffff;
    border-left: 0;
  }

  .story .item:nth-child(odd) .story-content .date {
    text-align: left;
  }

  .story .item:nth-child(odd) .story-content {
    text-align: left;
  }

  .couple-shape {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    padding-top: 0;
    margin-bottom: 16px;
  }

  .story .item .story-content {
    padding: 15px;
  }
}

@include media-breakpoint-down(sm) {
  .story .item .story-content,
  .story .item:nth-child(even) .story-content,
  .story .item.expanded .story-content {
    width: calc(100vw - 150px);
    margin-left: 85px;
  }

  .story-content2:before {
    display: none;
  }
  .story-content2:after {
    display: none;
  }

  .section-title h2 {
    font-size: 50px;
  }
  .story-content-m:before {
    display: none;
  }
  .story-content-m:after {
    display: none;
  }
  .story .story-icon {
    position: absolute;
    top: 10%;
    right: -30px;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .story-icon-img {
    height: 55px;
    width: 55px;
  }
}
