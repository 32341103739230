@import 'customize';

@include media-breakpoint-up(md) {
  .welcome-area {
    background: url(../Images/welcome/welcome-img_lg.png);
  }
}

@include media-breakpoint-down(md) {
  .welcome-area {
    background: url(../Images/welcome/welcome-img_sm.png);
  }
}

.welcome-area {
  position: relative;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@supports (-webkit-overflow-scrolling: touch) {
  .welcome-area {
    background-attachment: scroll;
  }
}
.welcome-area:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgb(0, 0, 0, 0.8) 25%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.2) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.5;
}

.welcome-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.welcome-content .item {
  position: relative;
  color: #fff;
  z-index: 10;
}

.welcome-content .item h2 {
  font-size: 3.4rem;
}

.welcome-content .item h2.coming-soon-header {
  font-family: 'Brittany';
}

.welcome-content .item h3 {
  font-size: 2rem;
}

.welcome-content .item {
  margin: auto;
}

.welcome-content .item .hashtag {
  position: relative;
  font-size: 1.4rem;
  color: #fff;
}

.welcome-content .item p {
  position: relative;
  font-size: 1.4rem;
  color: #fff;
  // max-width: 700px;
}

.save-date-break {
  display: none;
}

.welcome-content .btn-outline-primary {
  border-color: white;

  color: white;
}

@media (max-width: 767px) {
  .welcome-content {
    padding: 60px 0 30px;
  }

  .welcome-content .item h2 {
    font-size: 2.4rem;
  }
  .welcome-content .item h3 {
    font-size: 1.8rem;
  }
  .save-date-break {
    display: block;
  }

  .welcome-content .item p {
    font-size: 1rem;
  }
}
