@import url('https://fonts.googleapis.com/css?family=Tangerine');
@import url('https://fonts.googleapis.com/css?family=Orbitron');
//@import url('https://fonts.googleapis.com/css?family=Playfair+Display:300,500,700');
@import '../Fonts/GeorgiaPro/style.css';
@import '../Fonts/Brittany/Brittany.css';

$primary: #000000;
$secondary: rgb(160, 116, 62);
$tertiary: #a0743e;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;
$light: #ffffff;
$dark: #000000;
$secondary-light: #bd9e78;
$secondary-dark: #845728;
$accent: #990000;

$min-contrast-ratio: 2.5;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1610px
);

@import '~bootstrap/scss/bootstrap.scss';

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  secondary-light: $secondary-light,
  secondary-dark: $secondary-dark
);
